import safeProxyFactoryAbi from './abis/safeProxyFactoryAbi.json'
import safeSingletonAbi from './abis/safeSingletonAbi.json'
import multiSendAbi from './abis/multiSendAbi.json'
import multiSendCallOnlyAbi from './abis/multiSendCallOnlyAbi.json'
import fallbackHandlerAbi from './abis/fallbackHandlerAbi.json'
import signMessageLibAbi from './abis/signMessageLibAbi.json'
import createCallAbi from './abis/createCallAbi.json'
import simulateTxAccessorAbi from './abis/simulateTxAccessorAbi.json'

export const contractNetworks = {
  [686868]: {
    safeSingletonAddress: '0x3D5177A6C60aF40B60d8c00683B160170A419858',
    safeProxyFactoryAddress: '0x427E933613Bd65E5f8B106DfE3541b68Ed86B131',
    multiSendAddress: '0x07F93f2c661CbD749D451E358685579e3564FEB6',
    multiSendCallOnlyAddress: '0x3b2fce2Cb8C9A9538F0D20eE333ebf552BDE3EFA',
    fallbackHandlerAddress: '0x47E7A87Afa01a797D2a09E37Cc16683F6e4B7519',
    signMessageLibAddress: '0x70D9E8CDcb9BB9058E68F3f7fc1Bdb7B3c2072cd',
    createCallAddress: '0x812995c19b5EA51751228A2A0c23d38eEd3ec7D1',
    simulateTxAccessorAddress: '0xbB7D99C78FE800466a144cFbdA4d83c742A5035b',
    safeSingletonAbi,
    safeProxyFactoryAbi,
    multiSendAbi,
    multiSendCallOnlyAbi,
    fallbackHandlerAbi,
    signMessageLibAbi,
    createCallAbi,
    simulateTxAccessorAbi,
  },
  [4200]: {
    safeSingletonAddress: '0x91DFD942058D9282133DA50d06718360Bb393016', //Safe L2
    safeProxyFactoryAddress: '0x0BcC7D382899D800A131764391b63c87bb590De8',
    multiSendAddress: '0x9690c10a34b4E6A132bD495473103fcD04D6e867',
    multiSendCallOnlyAddress: '0x924dfeB86FCE777360Cf3fd0Fc570811Dc33a84D',
    fallbackHandlerAddress: '0xD406Aa12f2bE37b5AC8F114DCA59A46D6A0E06c7', //CompatibilityFallbackHandler
    signMessageLibAddress: '0x54b39c2B4077431d8D53B9E1b92582D619D5b8b5',
    createCallAddress: '0xEEf2038A31a7fA11Ecd239Fb88f3FF5192F6f3D8',
    simulateTxAccessorAddress: '0xC87C60D984D0d7e534FA5698ceAE6b0204a0ECF5',
    safeSingletonAbi,
    safeProxyFactoryAbi,
    multiSendAbi,
    multiSendCallOnlyAbi,
    fallbackHandlerAbi,
    signMessageLibAbi,
    createCallAbi,
    simulateTxAccessorAbi,
  },
}
