import { Button } from '@mui/material'
import Link from 'next/link'
import { AppRoutes } from '@/config/routes'

const buttonSx = {
  maxWidth: ['156px', '162px'],
  padding: ['var(--space-1)', 'var(--space-1) var(--space-3)'],
  borderRadius: '6px',
  border: '1px solid var(--color-white-10)',
  background: 'var(--color-black-3)',
  color: 'var(--color-white)',
}

const CreateButton = ({ isPrimary }: { isPrimary: boolean }) => {
  return (
    <Link href={AppRoutes.newSafe.create} passHref legacyBehavior>
      <Button
        data-testid="create-safe-btn"
        disableElevation
        size="small"
        variant={isPrimary ? 'contained' : 'outlined'}
        sx={buttonSx}
        component="a"
      >
        Create account
      </Button>
    </Link>
  )
}

export default CreateButton
